<template>
  <div>
    <div
      :style="getElementStyle"
      v-show="data.properties.filed_content != 'Hide'"
    >
      <div>
        <!-- {{data}}  -->
        <component
          v-loading="loading"
          :is="selectedComponent || fieldMap[selectedField.inputType]"
          :data="{
            ...selectedField,
            validations: {
              ...selectedField.validations,
              required: isRequired,
            },
          }"
          :form="form"
          :is-view="isView"
          :hasLabel="hasLabel"
          :fieldsData="fieldsData"
          :isFromDocument="isFromDocument"
          :isEntityVariable="true"
          :originalField="data"
          :formbuilderDetails="formbuilderDetails"
          @entityDataUpdated="entityDataUpdated"
          @clearEntityFields="clearEntityFields"
          :checkIsDisabled="checkIsDisabled"
          @applyFormRules="rulesEventEmitter"
          :parentFormData="parentFormData"
          :dataTableRowIndex="dataTableRowIndex"
          :entityDataList="entityDataList"
          :parentField="parentEntityFiled"
          :savingData="savingData"
          :autoFillEntityData="autoFillEntityData"
          :highlight="highlight"
          @onNewRowAdded="emitRowAdded"
          @updateTableData="updateTableFieldData"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
// import templateConstants from "@/constants/lg-en/templates";

// import DateExecute from "../formComponentsExecute/DateExecute";
// import SingleLineTextExecute from "../formComponentsExecute/SingleLineTextExecute";
// import MultiLineTextExecute from "../formComponentsExecute/MultiLineTextExecute";
// import FileExecute from "../formComponentsExecute/FileExecute";
// import MultiSelectExecute from "../formComponentsExecute/MultiSelectExecute";
// import NumberExecute from "../formComponentsExecute/NumberExecute";
// import SelectExecute from "../formComponentsExecute/SelectExecute";
// import ListExecute from "../formComponentsExecute/ListExecute";
// import YesOrNoExecute from "../formComponentsExecute/YesOrNoExecute";
// import HeadingExecute from "../formComponentsExecute/HeadingExecute";
// import CheckBoxExecute from "../formComponentsExecute/CheckBoxExecute";
// import ESignatureExecute from "../formComponentsExecute/ESignatureExecute";

// import GlobalVariableExecute from "../formComponentsExecute/GlobalVariableExecute";
// import EntityVariableExecute from "../formComponentsExecute/EntityVariableExecute";

// import ParagraphExecute from "../formComponentsExecute/ParagraphExecute";
// import SingleLineContentExecute from "../formComponentsExecute/SingleLineContentExecute";
// import AuthorizedSignatureExecute from "../formComponentsExecute/AuthorizedSignatureExecute";
// import ImageExecute from "../formComponentsExecute/ImageExecute";
// import FormulaExecute from "../formComponentsExecute/FormulaExecute";
// import ActionButtonExecute from "../formComponentsExecute/ActionButtonExecute";
// import CurrencyExecute from "../formComponentsExecute/CurrencyExecute.vue";
// import HtmlExecute from "../formComponentsExecute/HtmlExecute";
// import EntityExecute from "../formComponentsExecute/EntityExecute";
// import PhoneCountryCodeExecute from "../formComponentsExecute/PhoneCountryCodeExecute";
// import DateTimeExecute from "../formComponentsExecute/DateTimeExecute";
// import DateTimeRangeExecute from "../formComponentsExecute/DateTimeRangeExecute";
// import DateRangeExecute from "../formComponentsExecute/DateRangeExecute";
// import TimeExecute from "../formComponentsExecute/TimeExecute";
// import RadioExecute from "../formComponentsExecute/RadioExecute";
// import WeekDaysExecute from "../formComponentsExecute/WeekDaysExecute.vue";
// import TimeRangeExecute from "../formComponentsExecute/TimeRangeExecute.vue";
// import CheckBoxGroupExecute from "../formComponentsExecute/CheckBoxGroupExecute.vue";
// import AggregateFunctionExecute from "../formComponentsExecute/AggregateFunctionExecute.vue";
// import StarRatingExecute from "../formComponentsExecute/StarRatingExecute.vue";
// import DataTableExecute from "../formComponentsExecute/DataTableExecute";

// import RadioButtonGroupExecute from "../formComponentsExecute/RadioButtonGroupExecute.vue";
// import AutoIncrementExecute from "../formComponentsExecute/AutoIncrementExecute.vue";
// import PayButtonView from "../formComponentsExecute/PaymentExecute.vue";
// import ConcatenateExecute from "../formComponentsExecute/ConcatenateExecute";

import { dataTableAllowedExcecuteFields } from "./index";

import { bus } from "../../../main";
export default {
  mixins: [userPermissionsHelper],
  name: "templates-formComponentsExecute-EntityVariableExecute",
  components: {
    //Execute
    ...dataTableAllowedExcecuteFields,
    // DateExecute: () => import("../formComponentsExecute/DateExecute.vue"),
    // DateTimeExecute: () =>
    //   import("../formComponentsExecute/DateTimeExecute.vue"),
    // DateTimeRangeExecute: () =>
    //   import("../formComponentsExecute/DateTimeRangeExecute.vue"),
    // DateRangeExecute: () =>
    //   import("../formComponentsExecute/DateRangeExecute.vue"),
    // ListExecute: () => import("../formComponentsExecute/ListExecute.vue"),
    // TimeExecute: () => import("../formComponentsExecute/TimeExecute.vue"),
    // TimeRangeExecute: () =>
    //   import("../formComponentsExecute/TimeRangeExecute.vue"),
    // FileExecuteView: () =>
    //   import("../formComponentsExecute/FileExecuteView.vue"),
    // MultiLineTextExecute: () =>
    //   import("../formComponentsExecute/MultiLineTextExecute.vue"),
    // SingleLineTextExecute: () =>
    //   import("../formComponentsExecute/SingleLineTextExecute.vue"),
    // MultiSelectExecute: () =>
    //   import("../formComponentsExecute/MultiSelectExecute.vue"),
    // NumberExecute: () => import("../formComponentsExecute/NumberExecute.vue"),
    // PhoneCountryCodeExecute: () =>
    //   import("../formComponentsExecute/PhoneCountryCodeExecute.vue"),
    // SelectExecute: () => import("../formComponentsExecute/SelectExecute.vue"),
    // YesOrNoExecute: () => import("../formComponentsExecute/YesOrNoExecute.vue"),
    // HeadingExecute: () => import("../formComponentsExecute/HeadingExecute.vue"),
    // CheckBoxExecute: () =>
    //   import("../formComponentsExecute/CheckBoxExecute.vue"),
    // RadioExecute: () => import("../formComponentsExecute/RadioExecute.vue"),
    // ESignatureExecute: () =>
    //   import("../formComponentsExecute/ESignatureExecute.vue"),
    // ParagraphExecute: () =>
    //   import("../formComponentsExecute/ParagraphExecute.vue"),
    // GlobalVariableExecute: () =>
    //   import("../formComponentsExecute/GlobalVariableExecute.vue"),
    // EntityVariableExecute: () =>
    //   import("../formComponentsExecute/EntityVariableExecute.vue"),
    // SingleLineContentExecute: () =>
    //   import("../formComponentsExecute/SingleLineContentExecute.vue"),
    // AuthorizedSignatureExecute: () =>
    //   import("../formComponentsExecute/AuthorizedSignatureExecute.vue"),
    // ImageExecute: () => import("../formComponentsExecute/ImageExecute.vue"),
    // FormulaExecute: () => import("../formComponentsExecute/FormulaExecute.vue"),
    // ActionButtonExecute: () =>
    //   import("../formComponentsExecute/ActionButtonExecute.vue"),
    // HtmlExecute: () => import("../formComponentsExecute/HtmlExecute.vue"),
    // AggregateFunctionExecute: () =>
    //   import("../formComponentsExecute/AggregateFunctionExecute.vue"),
    // VideoExecute: () => import("../formComponentsExecute/VideoExecute.vue"),
    // HorizontalLineExecute: () =>
    //   import("../formComponentsExecute/HorizontalLineExecute.vue"),
    // DIVExecute: () => import("../formComponentsExecute/DIVExecute.vue"),
    // CurrencyExecute: () =>
    //   import("../formComponentsExecute/CurrencyExecute.vue"),
    // EntityExecute: () => import("../formComponentsExecute/EntityExecute"),
    // CheckBoxGroupExecute: () =>
    //   import("../formComponentsExecute/CheckBoxGroupExecute"),
    // //RadioButtonGroupExecute
    // RadioGroupExecute: () =>
    //   import("../formComponentsExecute/RadioButtonGroupExecute"),
    // QuestionExecute: () =>
    //   import("../formComponentsExecute/QuestionExecute.vue"),
    // AutoIncrementExecute: () =>
    //   import("../formComponentsExecute/AutoIncrementExecute.vue"),
    // StarRatingExecute: () =>
    //   import("../formComponentsExecute/StarRatingExecute.vue"),

    // WeekDaysExecute: () =>
    //   import("../formComponentsExecute/WeekDaysExecute.vue"),
    DataTableExecute: () => import("../formComponentsExecute/DataTableExecute"),
    // PaymentLinkExecute: () =>
    //   import("../formComponentsExecute/PaymentExecute.vue"),
    // PayablesExecute: () =>
    //   import("../formComponentsExecute/PayablesExecute.vue"),
    // PayVariableExecute: () =>
    //   import("../formComponentsExecute/PayVariableExecute.vue"),
    // ConcatenateExecute: () =>
    //   import("../formComponentsExecute/ConcatenateExecute.vue"),
    // EntityTableExecute: () =>
    //   import("../formComponentsExecute/EntityTableExecute"),
    // DateExecute,
    // FileExecute,
    // MultiLineTextExecute,
    // SingleLineTextExecute,
    // MultiSelectExecute,
    // NumberExecute,
    // SelectExecute,
    // ListExecute,
    // YesOrNoExecute,
    // HeadingExecute,
    // CheckBoxExecute,
    // ESignatureExecute,
    // GlobalVariableExecute,
    // EntityVariableExecute,
    // ParagraphExecute,
    // SingleLineContentExecute,
    // AuthorizedSignatureExecute,
    // ImageExecute,
    // FormulaExecute,
    // ActionButtonExecute,
    // HtmlExecute,
    // EntityExecute,
    // CurrencyExecute,
    // PhoneCountryCodeExecute,
    // DateTimeExecute,
    // DateTimeRangeExecute,
    // DateRangeExecute,
    // TimeExecute,
    // RadioExecute,
    // WeekDaysExecute,
    // TimeRangeExecute,
    // CheckBoxGroupExecute,
    // AggregateFunctionExecute,
    // StarRatingExecute,
    // DataTableExecute,
    // RadioButtonGroupExecute,
    // AutoIncrementExecute,
    // PayButtonView,
    // ConcatenateExecute,
  },
  props: [
    "data",
    "value",
    "form",
    "isView",
    "hasLabel",
    "fieldsData",
    "isFromDocument",
    "checkIsDisabled",
    "parentFormData",
    "dataTableRowIndex",
    "entity_id",
    "currentEntity",
    "rowsData",
    "autoFillEntityData",
    "savingData",
    "colorFields",
    "formbuilderDetails",
    "highlight"
  ],
  computed: {
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("entitiesData", [
      "getAllEntityRelationshipDataByIds",
      "getAllEntitiesDataByIds",
    ]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("companyTemplates", [
      "getSingleCompanyTemplate",
      "getTemplateDataTempVariable",
    ]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),
    getStyle() {
      const widthValue =
        this.data.width !== undefined ? this.data.width : this.data.min_width;
      return `height: ${
        this.data.height - 30
      }px; width: ${widthValue}px !important;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getAllUsers() {
      return this.getUsersList && this.getUsersList.data
        ? this.getUsersList.data
        : [];
    },

    getAllEntities() {
      return this.getAllEntitiesData && this.getAllEntitiesData.data
        ? this.getAllEntitiesData.data
        : [];
    },
    getPrimaryDataForUser() {
      return (user) => {
        let primaryFields = user.user_type.allowed_fields.filter(
          (e) => e.primary
        );

        let data = [];

        primaryFields.forEach((field) => {
          data.push(user[field.key]);
        });

        return data.join(" ");
      };
    },
    getPrimaryDataForEntity() {
      return (entity) => {
        let primaryFields = entity.entity_id.primary_fields || [];

        let data = [];
        primaryFields.forEach((field) => {
          if (entity.entity_data) {
            data.push(entity.entity_data[field.key]);
          }
        });

        return data.join(" ");
      };
    },
    isRequired() {
      if (this.data?.validations) {
        return this.data.validations.required;
      }
      return this.selectedField?.validations?.required;
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      selectedEntity: {},
      selectedField: {},
      selectedEntityFields: [],
      selectedComponent: "",
      refField: {},
      loading: false,
      actionFieldData: [],
      entityData: [],
      //data table fields data for entity Variable
      entityDataList: [],
      parentEntityFiled: {},
      parentValue: null,
      // label:'',
      paymentFields: [
        {
          label: "Transaction Id",
          key: "txn_id",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Gateway", key: "gateway", input_type: "SINGLE_LINE_TEXT" },
        { label: "Name", key: "name", input_type: "SINGLE_LINE_TEXT" },
        { label: "Email", key: "email", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Phone Number",
          key: "phoneNumber",
          input_type: "PHONE_COUNTRY_CODE",
        },
        {
          label: "Payment Type",
          key: "paymentType",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Method", key: "method", input_type: "SINGLE_LINE_TEXT" },
        { label: "Currency", key: "currency", input_type: "SINGLE_LINE_TEXT" },
        { label: "Amount", key: "amount", input_type: "CURRENCY" },
        { label: "Payment Date", key: "paymentDate", input_type: "DATE" },
        { label: "Status", key: "status", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Payment Session",
          key: "paymentSession",
          input_type: "SINGLE_LINE_TEXT",
        },
        {
          label: "Amount Refunded",
          key: "amountRefunded",
          input_type: "CURRENCY",
        },
        {
          label: "Description",
          key: "description",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Refund Date", key: "refundDate", input_type: "DATE" },
      ],
      fieldMap: {
        DATE: "DateExecute",
        FILE: "FileExecute",
        MULTI_LINE_TEXT: "MultiLineTextExecute",
        SINGLE_LINE_TEXT: "SingleLineTextExecute",
        MULTI_SELECT: "MultiSelectExecute",
        NUMBER: "NumberExecute",
        SELECT: "SelectExecute",
        LIST: "ListExecute",
        YES_OR_NO: "YesOrNoExecute",
        HEADING: "HeadingExecute",
        CHECKBOX: "CheckBoxExecute",
        SIGNATURE: "ESignatureExecute",
        GLOBAL_VAIRLABE: "GlobalVariableExecute",
        ENTITY_VARIABLE: "EntityVariableExecute",
        PARAGRAPH: "ParagraphExecute",
        SINGLE_LINE_CONTENT: "SingleLineContentExecute",
        AUTHORIZED_SIGNATURE: "AuthorizedSignatureExecute",
        IMAGE: "ImageExecute",
        FORMULA: "FormulaExecute",
        ACTION_BUTTON: "ActionButtonExecute",
        HTML: "HtmlExecute",
        ENTITY: "EntityExecute",
        CURRENCY: "CurrencyExecute",
        PHONE_COUNTRY_CODE: "PhoneCountryCodeExecute",
        DATE_TIME: "DateTimeExecute",
        DATE_TIME_RANGE: "DateTimeRangeExecute",
        DATE_RANGE: "DateRangeExecute",
        TIME: "TimeExecute",
        RADIO: "RadioExecute",
        WEEKDAYS: "WeekDaysExecute",
        TIME_RANGE: "TimeRangeExecute",
        CHECKBOX_GROUP: "CheckBoxGroupExecute",
        AGGREGATE_FUNCTION: "AggregateFunctionExecute",
        STAR_RATING: "StarRatingExecute",
        DATA_TABLE: "DataTableExecute",
        RADIO_BUTTON_GROUP: "RadioButtonGroupExecute",
        AUTO_INCREMENT_NUMBER: "AutoIncrementExecute",
        PAY_BUTTON: "PayButtonView",
        CONCATENATE: "ConcatenateExecute",
      },
    };
  },

  updated: function () {
    this.$nextTick(function () {
      this.$emit("UIUpdated", {});
    });
  },
  async mounted() {
    this.options = this.data.options || [];
    await this.fetchOptions();
    bus.$on("fill-entity-data", (data) => {
      if (data.entity_data) {
        let entityFeidKey = this.refField.key;

        if (
          data.parent_key == this.data.global_variable_entity_parent &&
          data.entity_data[entityFeidKey]
        ) {
          this.$set(this.form, this.data.key, data.entity_data[entityFeidKey]);
          if (this.selectedField.inputType == "ENTITY") {
            this.$set(
              this.form,
              this.data.key + "/name",
              data.entity_data[entityFeidKey + "/name"]
            );
          }
        }

        if (
          data.parent_key == this.data.global_variable_entity_parent &&
          !data.entity_data[entityFeidKey]
        ) {
          this.$set(this.form, this.data.key, "");
        }
      }
    });
  },
  methods: {
    emitRowAdded() {
      this.$emit("onNewRowAdded", {});
    },
    updateTableFieldData(details) {
      this.$emit("updateTableData", {
        ...details,
      });
    },
    rulesEventEmitter() {
      this.$emit("applyFormRules");
    },
    async fetchOptions() {
      try {
        if (this.data.data_table_field_index > -1) {
          // we need to inlcude table entity fields here
          this.fieldsData
            .filter(
              (field) =>
                field.inputType == "DATA_TABLE" ||
                field.input_type == "ENTITY_TABLE"
            )
            .forEach(
              async (field) =>
                await this.checkWithParentKey(field.data_table_columns)
            );
        } else {
          await this.checkWithParentKey(this.fieldsData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkEntityFields(data, changed = false) {
      try {
        if (data) {
          if (data == "LOADMORE") {
            this.page++;
            this.form[this.data.key] = "";
            this.fetchEntitiesDataForTable();
            return;
          }
          let selectedData = this.entitiesData.find((e) => e._id == data);
          let label = this.getLabel(selectedData, 0);
          if (!this.isView) {
            if (this.dataTableRowIndex > -1) {
              this.$emit(
                "entityDataUpdated",
                selectedData,
                this.data,
                label,
                changed,
                this.dataTableRowIndex
              );
            } else {
              // need to check data
              this.$emit(
                "entityDataUpdated",
                selectedData,
                this.data,
                label,
                changed
              );

              bus.$emit(
                "entityDataUpdated",
                selectedData,
                this.data,
                label,
                changed
              );
            }
          }
        } else {
          this.$emit("clearEntityFields", this.data, this.dataTableRowIndex);
          bus.$emit("clearEntityFields", this.data);
        }
      } catch (err) {
        console.log("checkEntityFields",data);
      }
    },

    async checkWithParentKey(fieldsData) {
      if (this.data.do_not_apply) {
        this.selectedField = {
          ...this.data,
          ...{
            is_entity_field: true,
          },
        };
        return;
      }
      let parentField = fieldsData.find(
        (e) => e.key == this.data.relationship_key
      );
      if (!parentField) {
        // data table entity fields
        parentField =
          this.rowsData &&
          this.rowsData[0] &&
          this.rowsData[0].fields &&
          this.rowsData[0].fields.find((e) => {
            if (e.key == this.data.relationship_key) {
              return { ...e, dataTable: true };
            }
          });
      }

      if (parentField && parentField.entity_id) {
        if (this.data.global_variable_entity_field.includes("~")) {
          let relationshipId =
            this.data.global_variable_entity_field.split("~")[0];
          let filters = this.data?.filters ? this.data.filters : [];
          this.selectedField = {
            action_button_repeating_template_relationships: [],
            input_type: "ENTITY",
            inputType: "ENTITY",
            content: "",
            selected_fields: [],
            formula: "",
            function_type: "",
            validations: {
              required: false,
              type: "OBJECT_ID",
              data_type: "OBJECT_ID",
            },
            defaultCountry: "US",
            countryCodeSelector: true,
            countryDisable: false,
            styles: {
              font: {
                name: "Helvetica",
                size: 16,
                style: 0,
                color: "#000000",
              },
            },
            properties: {
              hideLabel: false,
              prefix: false,
              is_unique: false,
              personal_data: false,
              personal_data_type: "",
              is_encrypted: false,
            },
            decimals: null,
            date_result_type: "",

            filled_by: this.data.filled_by,
            type: "FIELD",

            is_global_variable: false,

            filters: [
              ...filters,
              ...[
                {
                  field: "self#related_to/1",
                  operator: "=",
                  value: "",
                  query_type: "AND",
                  data_type: "RELATION",
                  data_source: "self_field",
                  value_field: this.data.global_variable_entity_parent,
                  relation_entity_id: parentField.entity_id,
                },
              ],
            ],
            entity_id: relationshipId,
            visibility: true,
            height: this.data.height,
            width: this.data.width,
          };

          this.selectedField.is_entity_field = false;
          this.setEntityFields();
        } else if (this.data.global_variable_entity_field.includes("#")) {
          let templateId = this.data.global_variable_entity_field.includes("#")
            ? this.data.global_variable_entity_field.split("#")[0]
            : this.data.global_variable_entity_field;
          let tempData;
          if (
            this.getTemplateDataTempVariable &&
            this.getTemplateDataTempVariable[templateId]
          ) {
            tempData = JSON.parse(
              JSON.stringify(this.getTemplateDataTempVariable[templateId])
            );
          } else {
            await this.$store.dispatch(
              "companyTemplates/fetchSingleCompanyTemplate",
              templateId
            );
            if (this.getSingleCompanyTemplate) {
              tempData = this.getSingleCompanyTemplate;
              let d = this.getTemplateDataTempVariable;
              if (d) {
                d[templateId] = this.getSingleCompanyTemplate;
              } else {
                d = {};
                d[templateId] = this.getSingleCompanyTemplate;
              }
              this.$store.commit(
                "companyTemplates/setTemplateDataTempVariable",
                d,
                { root: true }
              );
            }
          }
          if (tempData?.sections) {
            this.entitiesData = tempData;
            let selectedEntity = tempData;
            this.selectedEntity = selectedEntity;
            this.selectedEntityFields = [];
            this.selectedEntityFields = this.getTemplateFields(tempData);
            let templateId = this.data.global_variable_entity_field.includes(
              "#"
            )
              ? this.data.global_variable_entity_field.split("#")[1]
              : this.data.global_variable_entity_field;
            this.selectedField = this.selectedEntityFields.find(
              (f) => f.key == templateId
            );
            if (this.selectedField) {
              if (this.data?.filters) {
                this.selectedField.filters = this.data.filters || [];
              }
              this.selectedField.filled_by = this.data.filled_by;
              this.selectedField.is_entity_field = true;
              if (this.data.rules) {
                this.selectedField.rules = this.data.rules || [];
              }
              if (this.selectedField.inputType == "PAY_BUTTON") {
                if (this.data.global_variable_pay_field) {
                  let selectedFieldData = {};
                  selectedFieldData = this.paymentFields.find(
                    (field) => field.key == this.data.global_variable_pay_field
                  );
                
                  this.selectedField.inputType = selectedFieldData.input_type;
                  // if(this.field.global_variable_entity_field )
                  // {
                  // let splitValues= this.field.global_variable_entity_field.split("#");

                  // this.field.key=splitValues[1]+'_'+fieldId;
                  // this.selectedField.inputType = selectedFieldData.input_type;
                }
                // else{
                //   this.selectedField={...selectedField}
                // }
              }
            }
          }
        }
        if (this.selectedField) {
          this.selectedField.height = this.data.height;
          this.$set(
            this.selectedField,
            "width",
            this.data.width
          );
          this.selectedField.is_entity_variable = true;
          this.$set(
            this.selectedField,
            "allow_multiple",
            this.data.allow_multiple
          );
          if (this.selectedField?.inputType == "DATA_TABLE") {
            let existedColums = [...this.selectedField.data_table_columns];
            this.selectedField.data_table_columns = (
              this.data.data_table_columns ||
              this.selectedField.data_table_columns ||
              []
            ).map((el) => {
              el["is_entity_variable"] = true;
              let existedField = existedColums.find((fl) => fl.key == el.key);
              if (
                existedField?.input_type == "ENTITY_VARIABLE" &&
                existedField?.field_assignable == "editable"
              ) {
                el["field_assignable"] = existedField.field_assignable;
              } else if (this.data.field_assignable == "update_parent") {
                if (!el.field_assignable) {
                  el["field_assignable"] = "read_only";
                }
              } else {
                el["field_assignable"] =
                  this.data.field_assignable || "read_only";
              }
              if (el.filters && el.filters.length) {
                // el.filters = [];
              }
              if (existedField?.input_type == "FORMULA") {
                el.selected_fields = existedField.selected_fields;
                el.result_type = existedField.result_type;
              }
              return el;
            });
            existedColums.forEach((e, index) => {
              let existedField = this.selectedField.data_table_columns.find(
                (fl) => fl.key == e.key
              );
              if (!existedField) {
                this.selectedField.data_table_columns = [
                  ...this.selectedField.data_table_columns.slice(0, index),
                  e,
                  ...this.selectedField.data_table_columns.slice(index),
                ];
                this.selectedField.data_table_columns.push(e);
              }
            });
            if (this.data.data_table_filters) {
              this.$set(
                this.selectedField,
                "data_table_filters",
                this.data.data_table_filters
              );
            }
            if (this.data.rules) {
              this.$set(this.selectedField, "rules", this.data.rules);
            }
            if (this.data.data_table_filter_query) {
              this.$set(
                this.selectedField,
                "data_table_filter_query",
                this.data.data_table_filter_query
              );
            }
          }
          if (this.data.auto_select_one_data) {
            this.selectedField["auto_select_one_data"] =
              this.data.auto_select_one_data;
          }
          if (this.data?.alow_data_table_add) {
            this.selectedField["alow_data_table_add"] =
              this.data.alow_data_table_add;
          }
          if (this.data?.alow_data_table_edit) {
            this.selectedField["alow_data_table_edit"] =
              this.data.alow_data_table_edit;
          }
          if (this.data.properties) {
            this.$set(this.selectedField, "properties", this.data.properties);
          }
          if (this.data.field_assignable) {
            this.$set(
              this.selectedField,
              "field_assignable",
              this.data.field_assignable
            );
          } else {
            this.$set(this.selectedField, "field_assignable", "read_only");
          }
          this.setEntityFields();
        }
        // setTimeout(async () => {
        //   this.setEntityFields();
        // },2000);
      }
      // else
      // {
      //   //
      //   this.selectedField=this.data
      // }
      if (!this.selectedField) {
        this.selectedField = this.data;
        this.selectedField.is_entity_variable = true;
      }
    },

    entityDataUpdated(param1, param2, param3, param4, index) {
      this.$emit("entityDataUpdated", param1, param2, param3, param4, index);
    },
    clearEntityFields(data) {
      this.$emit("clearEntityFields", data, this.dataTableRowIndex);
    },

    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    setEntityFields() {
      if (this.selectedField) {
        this.refField = { ...this.selectedField };
        this.selectedField.label = this.data.label ? this.data.label : "";
        this.selectedField.key = this.data.key;
        this.selectedField.relationship_key = this.data.relationship_key;
        this.selectedField.filters = this.selectedField.filters || [];
        // if(this.data.inputType=="PAY_BUTTON" && this.data.input_type=="ENTITY_VARIABLE")
        // {
        //   //assign Form data
        // }
        this.selectedComponent = this.fieldMap[this.selectedField.inputType];
      }
    },

    async getCompoentForField(data) {
      try {
        let params = "form=true&content=true";
        await this.$store.dispatch("standardFields/getFieldLists", params);
        if (this.getFList) {
          let dFieldList = [
            ...this.getFList.allFormFields,
            ...this.getFList.allContentFields,
          ];
          let field = dFieldList.find((x) => x.inputType == data.input_type);
          if (field) {
            return field.components.execute;
          }
          return "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchEntitiesData(entityDataId, relationships, relationshipEntityId) {
      let relationship = relationships.find(
        (x) => x.entity_id == relationshipEntityId
      );
      if (relationship) {
        await this.$store.dispatch(
          "entitiesData/fetchEntityRelationshipsDataByIds",
          {
            relationship_entity_id: relationshipEntityId,
            relationship_id: relationship._id,
          }
        );

        if (this.getAllEntityRelationshipDataByIds) {
          let relationshipData = this.getAllEntityRelationshipDataByIds.find(
            (x) => x.entity_data_id == entityDataId
          );
          if (relationshipData) {
            await this.$store.dispatch("entitiesData/getEntitiesDataByIds", {
              entity_data_ids: relationshipData.relationship_entity_data_ids,
            });
            if (this.getAllEntitiesDataByIds) {
              this.data.options = this.getAllEntitiesDataByIds.data;
            }
          }
        }
      }
    },
    async getFieldData(templateId) {
      try {
        let tempData;
        if (
          this.getTemplateDataTempVariable &&
          this.getTemplateDataTempVariable[templateId]
        ) {
          tempData = this.getTemplateDataTempVariable[templateId];
        } else {
          await this.$store.dispatch(
            "companyTemplates/fetchSingleCompanyTemplate",
            templateId
          );
          if (this.getSingleCompanyTemplate) {
            tempData = this.getSingleCompanyTemplate;
            let d = this.getTemplateDataTempVariable;
            if (d) {
              d[templateId] = this.getSingleCompanyTemplate;
            } else {
              d = {};
              d[templateId] = this.getSingleCompanyTemplate;
            }
            this.$store.commit(
              "companyTemplates/setTemplateDataTempVariable",
              d,
              { root: true }
            );
          }
        }
        return tempData;
      } catch (error) {
        console.log("getFieldData",error);
      }
    },
    async fetchEntityDataByDataId(form, data) {
      // this.fieldsData
      // let entityVariableInfo = this.lodash.filter(
      //   this.fieldsData,
      //   function (element) {
      //     return (
      //       element.inputType === "ENTITY" &&
      //       element.key == data.relationship_key
      //     );
      //   }
      // );
      let entityVariableInfo = this.fieldsData.filter(element => element.inputType === "ENTITY" && element.key == data.relationship_key)
      // console.log("Fetch Entity",this.entity_id,this.currentEntity,entityVariableInfo)
      if (entityVariableInfo && entityVariableInfo.length) {
        let params = {
          entity_id: entityVariableInfo[0].entity_id,
          filters: [],
          relationship: {},
          limit: 500,
          page: 1,
        };
        if (
          this.data.relationship_key &&
          this.form &&
          this.form[this.data.relationship_key]
        ) {
          if (typeof this.form[this.data.relationship_key] == "object") {
            params.entity_data_ids = this.form[this.data.relationship_key];
          } else {
            params.entity_data_ids = [this.form[this.data.relationship_key]];
          }
        }
        await this.$store.dispatch(
          "entities/fetchEntityRecordsForTable",
          params
        );
        if (this.getEntityRecordsForTable) {
          let parent = (this.fieldsData || []).find(
            (e) => e.key == this.data.relationship_key
          );
          if (
            this.data.relationship_key &&
            this.form &&
            this.form[this.data.relationship_key]
          ) {
            if (parent?.allow_multiple) {
              let dataIds = this.form[this.data.relationship_key];
              this.entityDataList = this.getEntityRecordsForTable.data.filter(
                (e) => dataIds.indexOf(e._id) != -1
              );
            } else {
              let fieldDataId = [this.form[this.data.relationship_key]];
              this.entityDataList = this.getEntityRecordsForTable.data.find(
                (e) => e._id == fieldDataId
              );
            }
          }
          this.parentEntityFiled = entityVariableInfo[0];
        }
      }
    },
  },
  watch: {
    "data.global_variable_entity_field": {
      async handler() {
        // do stuff
        await this.fetchOptions();
      },
      deep: true,
    },
    getEntityDataByEntityId: {
      async handler() {
        // this.entitiesData = this.getEntityDataByEntityId.data;
      },
    },
    form: {
      async handler(data) {
        //form data updated
        if (
          this.data.inputType == "DATA_TABLE" ||
          this.data.inputType == "ENTITY_TABLE"
        ) {
          if (
            !this.areArgumentsEqual(
              this.parentValue,
              data[this.data.relationship_key]
            )
          ) {
            this.parentValue = data[this.data.relationship_key];
            this.fetchEntityDataByDataId(this.form, this.data);
          }
        }
        // this.fetchOptions();
      },
      deep: true,
    },
    // "data":{
    //   async handler(){
    //       this.fetchOptions()
    //   }
    // },
    // "rowsData":{
    //   async handler(){
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
